var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"noodle-frontend@56.1873.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/qa";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import * as SentryTracing from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import getRelease from './scripts/getRelease';
import getFingerprint from './sentry/getFingerprint';
import ignoreEvent from './sentry/ignoreEvent';

const environment = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const debug = process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true';
const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN;
const enabled = process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true';
const release = getRelease();

Sentry.init({
  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (ignoreEvent({ error, event })) {
      return null;
    }

    const fingerprint = getFingerprint({ error, event });
    if (fingerprint) {
      event.fingerprint = fingerprint; // eslint-disable-line no-param-reassign
    }

    return event;
  },
  debug,
  dsn,
  enabled,

  environment,
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
  // but not "Non-Error exception captured" because we were throwing objects for a while.
  ignoreErrors: [
    'Non-Error promise rejection captured with value: undefined',
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    'Could not verify one time passcode.',
    'Could not send one time passcode.',
    'Mixpanel error: Bad HTTP status: 0',

    // ignore for now, not prioritizing fixing and just cluttering sentry.
    'The user aborted a request',
    'Network Error',
    'Cancel rendering route',
    'Loading initial props cancelled',
    /^Route did not complete loading/,
    'Inital load had uninitialized params',
    /^Abort fetching component for route/,
  ],
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
  release,
  tracesSampler: (samplingContext) => samplingContext.sampleAll ? 1 : 0.2,
});

// DO NOT REMOVE THIS.
// Sentry.startTransaction works without this in development, but not production.
SentryTracing.addExtensionMethods();
